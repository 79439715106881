.sidebar {
  width: 170px; min-height: calc(100vh - 50px);
  /* background-color: rgb(251, 251, 255); */

  box-shadow: 0px 5px 5px lightgray;
}

.sidebarWrapper { padding: 0 10px;}
.sidebarMenu { margin-bottom: 10px;} 
.sidebarTitle { padding: 5px 5px; font-size: 13px; color: rgb(187, 186, 186);}
.sidebarList { list-style: none; padding: 0px; }

.sidebarListItem {
  display: flex; width: auto;
  padding: 5px 10px; align-items: center;
  border-radius: 10px;cursor: pointer;
}

.sidebarListItem {
  display: flex; width: auto;
  padding: 5px 10px; align-items: center;
  border-radius: 10px;cursor: pointer;
  color: gray;
}
.sidebarListItem.selected {
  display: flex; width: auto;
  padding: 5px 10px; align-items: center;
  border-radius: 10px;cursor: pointer;
  background-color: #A576F41A; color: #A576F4;
}

.sidebarListItem.active, .sidebarListItem:hover { background-color: rgb(240, 240, 255);}

.sidebarIcon{ margin-right: 5px;font-size: 20px !important;}

.container{
    display: flex;
}
.link{
    text-decoration: none;
    color: inherit;
}

.pageContainer {
    /* width: calc(100vw - 240px); */
    /* min-width: calc(100vw - 280px); */
    display: flex;
    flex-direction: column;
}
.pageLabel {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
    margin-top: 12px;
}
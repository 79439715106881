.pageTable{
  width: 100%;
  margin-top: 10px;
  border-radius: 8px; outline: 1px solid #8d8dad; border-collapse: collapse;
  align-items: center; overflow: hidden; display: table; font-size: 16px;
}

.pageTable th{
  background-color : #8d8dad;
  height: 32px;
  color : #fff; font-weight: 400;
  align-items: center; justify-content: center; text-align: center;
}
.pageTable tr{ width: 100%;}
.pageTable tr:hover{ background-color: #b7c3d4; color: #ffffff; }
.pageTable td {
  height: 36px;
  border-left: 1px solid #8d8dad;border-collapse: collapse;
  display: table-cell; align-items: center; justify-content: center; text-align: center;
}

.pageTable .button{
  width: 80%;
  height: 90%;
  background-color: #8d8dad;
  border-radius: 8px;
  box-shadow: 0;
  color: white;
  margin: auto;
  user-select: none;
  cursor: pointer;
}

.pageTable .button-disabled{
  width: 80%;
  height: 90%;
  background-color: #bfbfbf;
  border-radius: 8px;
  box-shadow: 0;
  color: white;
  margin: auto;
  user-select: none;
}

.pageTable .background-blue-light{
  background-color: #f4f6ff;
}
.pageTable .background-blue{
  background-color: #adbafd;
}
.pageTable .background-yellow{
  background-color: rgb(253, 251, 173);
}
.pageTable .background-yellow:hover{
  background-color: #b7c3d4;
  color: #ffffff;
}
.pageTable .background-gray{
  background-color: rgb(166, 165, 165);
}
.pageTable .background-gray:hover{
  background-color: #b7c3d4;
  color: #ffffff;
}
.pageTable .background-light-gray{
  background-color: rgb(213, 213, 213);
}
.pageTable .background-light-gray:hover{
  background-color: #b7c3d4;
  color: #ffffff;
}
.pageTable .background-red{
  background-color: rgb(185, 81, 92);
}
.pageTable .background-red:hover{
  background-color: #b7c3d4;
  color: #ffffff;
}

.pageTable .button-checked{
  width: 50%;
  background-color: white;
  border-radius: 0;
  box-shadow: 0;
  color: #000;
  margin: auto;
  user-select: none;
  border: 1px solid #000;
  cursor: pointer;
}
.pageTable .button-checked-2{
  width: 50%;
  background-color: #8d8dad;
  border-radius: 0;
  box-shadow: 0;
  color: #000;
  margin: auto;
  user-select: none;
  border: 1px solid #000;
  cursor: pointer;
}
.pageTable .button-unchecked{
  width: 50%;
  background-color: white;
  border-radius: 0;
  box-shadow: 0;
  color: white;
  border: 1px solid #000;
  margin: auto;
  user-select: none;
  cursor: pointer;
}